import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/kylesoeltz/git/reconnect-v2/src/components/layouts/post-layout.js";
import { graphql } from "gatsby";
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        authors {
          id
          name
        }
        categories {
          id
          name
        }
      }
    }
    allMdx(
      sort: {fields: [frontmatter___date], order: DESC}
      limit: 6
      filter: {frontmatter: {category: {eq: "hiking-in-new-england" }}}
    ) {
      edges {
        node {
          frontmatter {
            author
            date
            path
            title
            featuredImage
          }
          slug
          timeToRead
          excerpt(truncate: false, pruneLength: 200)
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Figcaption = makeShortcode("Figcaption");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`Miles:`}</strong>{`12.3`}</p>
    <p><strong parentName="p">{`Elevation Gain:`}</strong>{` 3763’`}</p>
    <p><strong parentName="p">{`Difficulty:`}</strong>{`Hard`}</p>
    <p>{` Typically if you want to do Mount Passaconaway and Mount Whiteface as a loop you have to do it from the south. Further, doing it as an out and back from the North will take no less than 17.5 miles (compared to 10 miles from the south) and require you to do Mount Whiteface twice. But what if there was a way to do it as a loop from the north?`}</p>
    <p><a parentName="p" {...{
        "href": "/images/passaconway-whiteface-map.jpg"
      }}><img parentName="a" {...{
          "src": "passaconway-whiteface-map-377x500.jpeg",
          "alt": "Map of Passaconaway-Whiteface"
        }}></img></a></p>
    <Figcaption mdxType="Figcaption">Map of the Passaconaway/Whiteface route. Green is the marked trail and blue is the abandoned trail. [View larger map.](/images/passaconway-whiteface-map.jpg)</Figcaption>
    <p>{` Enter the abandoned trail off the backside of Mount Passaconaway. This is my favorite way to do these peaks, as it has some fantastic views going down and adds some excitement with the navigation. Be warned, this trail should only be attempted if you have experience bushwhacking. There are several sections where the trail teeters out and you will need to rely on your own ability to continue navigating through. I also recommend taking the trail down instead of up, as it is easier to follow going down.`}</p>
    <p><img parentName="p" {...{
        "src": "trail-up-passaconaway-411x500.jpg",
        "alt": "Trail Up Whiteface"
      }}></img></p>
    <Figcaption mdxType="Figcaption">The trail heading up to Mount Whiteface.</Figcaption>
    <h2>{`Start on the Downes Brook Trail off the Kancamagus Highway`}</h2>
    <p>{` Make sure to pay attention to the signs at the beginning of the hike, as there are a couple turns that could lead you up Mt. Potash, Mt. Hedgehog, or back to the parking lot. Once you’ve gone past these forks, you will have a straight walk along the Downes Brook Trail for 4.9 miles. For the first 4 miles it is a very easy, gradual grade. The last mile you will see some gain in steepness before hitting the junction of the Kate Sleeper Trail. You will want to turn left here and will be given a half mile of flat hiking before another steep section as you near the Mt. Whiteface summit.`}</p>
    <p><img parentName="p" {...{
        "src": "berries-on-whiteface-summit-375x500.jpg",
        "alt": "Berries on the summit of Whiteface"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Someone left berries on the summit cairn of Mount Whiteface.</Figcaption>
    <h2>{`Mount Whiteface and the Rollins Trail`}</h2>
    <p>{` At the end of the Kate Sleeper Trail, you will reach the junction for the Rollins Trail. Turn right and you will be a short .1 mile climb from the summit of Mt. Whiteface. After reaching the summit, you will backtrack down the Rollins Trail and follow it in the other direction for 2.4 miles. This section will offer some nice ridgeline hiking with gradual ups and downs.`}</p>
    <p><img parentName="p" {...{
        "src": "whiteface-ridgeline-500x488.jpg",
        "alt": "Whiteface Ridgeline"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Views off the Mount Whiteface ridgeline.</Figcaption>
    <h2>{`Dicey’s Mill Trail up Mount Passaconaway`}</h2>
    <p>{` You will eventually reach the junction for Dicey’s Mill Trail. Here you will take a left and follow signs for the Mt. Passaconaway summit. It will be about .9 miles of switchbacks until you reach the top.`}</p>
    <p><img parentName="p" {...{
        "src": "views-off-passaconway-500x291.jpg",
        "alt": "Views off Passaconaway Spur"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Views off of the Mount Passaconaway spur.</Figcaption>
    <h2>{`Finding the start of the abandoned trail`}</h2>
    <p>{` From the summit of Mt. Passaconaway you will want to take the .3 mile spur trail to the view. The start of the abandoned trail is on the left of the spur trail heading towards the view, about 10-30 feet before you reach the actual view point. It is not extremely clear at first, but with some looking you should be able to find it. It will be slightly more open than the surrounding forest, and there may be some debris (logs, branches, etc) piled around as a way of keeping the entrance secluded. Once on the trail it will be become immediately obvious within 10-20 feet of walking as the trail is very clear once you pass the hidden entrance.`}</p>
    <p><img parentName="p" {...{
        "src": "passaconway-whiteface-map-abandoned-trail-closeup-500x500.jpg",
        "alt": "Map of Abandoned Trail off Passaconaway"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Closeup of the abandoned trail.</Figcaption>
    <h2>{`Following the trail and it’s dicier sections`}</h2>
    <p>{` The trail is extremely easy to follow at the beginning. There may be some thicker sections, but there is a very clear footpath. This ease will fade, as the footpath will gradually become less clear and some searching and good backcountry navigational judgement will be required. Along the trail there are occasional cairns that other hikers have setup, so keep an eye out for them but also don’t rely on them completely.`}</p>
    <p><img parentName="p" {...{
        "src": "views-off-passaconaway-abandoned-trail-379x500.jpg",
        "alt": "Views of Passaconaway Abandoned Trail"
      }}></img></p>
    <Figcaption mdxType="Figcaption">The abandoned trail has some of the best views of the whole loop.</Figcaption>
    <p>{` You will eventually be led to a river, and the trail will cross over it several times. It is around the time of the river that the trail will become increasingly more difficult to follow, so it is good to know that this river eventually flows directly into the Downes Brook and links directly to the Downes Brook Trail. So if finding the trail becomes too difficult, just make sure to continue following the river.`}</p>
    <p><img parentName="p" {...{
        "src": "wildflowers-passaconaway-500x282.jpg",
        "alt": "Wildflowers on Passaconaway"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Some wildflowers along the river on the Passaconaway abandoned trail.</Figcaption>
    <h2>{`Linking back up to the Downes Brook Trail`}</h2>
    <p>{` Ultimately when the abandoned trail meets back up with the Downes Brook Trail, you will be on the East side of the river. When you link back up to the trail, you will turn right and follow the Downes Brook Trail for about 2 miles back to the parking lot.`}</p>
    <p><img parentName="p" {...{
        "src": "downes-brook-trail-442x500.jpg",
        "alt": "The Downes Brook Trail"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Heading back out along the Downes Brook Trail</Figcaption>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      